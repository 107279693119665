import * as React                  from "react";
import Layout                      from "../components/layout";
import { graphql, useStaticQuery } from "gatsby";

// markup
const AboutPage = () => {

	const contextData = useStaticQuery(graphql`{
	  	s3SourcedPage(Name: {eq: "about"}) {
			remotePage {
			  	childMarkdownRemark {
					html
			  	}
			}
	  	}
	}`)

	return (
		<Layout title="Jędrek Wojnar F.o.t.o.g.r.a.f.i.a | O mnie">
			<div dangerouslySetInnerHTML={{ __html: contextData.s3SourcedPage.remotePage.childMarkdownRemark.html }} />
		</Layout>
	)
}

export default AboutPage
